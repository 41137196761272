<template>
  <div class="church-members-friends template-1">
    <page-header class="mx-lg">
      <h1>{{ translations.tcReportCenter }}</h1>
    </page-header>
    <security-banner v-if="!allowPage" :i18n="translations.components"></security-banner>
    <div v-if="allowPage">
      <camp-selector @camp_changed="page_load($event)" :tooltip="translations.tcTemporarilyChangeCamps"
        :i18n="translations.components"></camp-selector>
      <page-body class="mx-lg">
        <data-table v-for="(category, ind) in reportCategoryList" :i18n="translations.components" :key="ind"
          :fields="reportFields" :items="translated_reports_list.filter((rpt) => {
            return rpt.Parent_rpc_key === category.Parent_rpc_key
          })
            " :searchTerm="`reportsTerm`" :addItems="addItems" :title="translateSection(category.ParentCategory)"
          :toggle="true" :toggleMe="openSection(category.ParentCategory) === $route.path.split('/')[3]" :hideHeader="true"
          :hideMe="hideCondition(category)"></data-table>
      </page-body>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import CampSelect from '@/components/CampSelect.vue'
import { countryBreadcrumbMixin } from '@/mixins/breadcrumbMixinFactory'
import DataTable from '@/components/DataTable.vue'
import pageBody from '@/components/page-components/PageBody.vue'
import pageFooter from '@/components/page-components/PageFooter.vue'
import pageHeader from '@/components/page-components/PageHeader.vue'
import SecurityBanner from '@/components/SecurityBanner'
import { securityMixin } from '@/mixins/securityMixin'
import ToggleArrow from '@/components/toggle-arrow/ToggleArrow.vue'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'report-center',
  mixins: [securityMixin, translationMixin, countryBreadcrumbMixin],
  data() {
    return {
      translations: {},
      secured_controls: {
        view_country_member_list_report: '02464731-540b-4f9f-8b32-f731d4720993',
        view_secure_reports_section: '90f8651a-5682-4cf1-87db-acca1f35b023',
        view_909_secure_reports: '5dbb3b9f-305c-4cf9-a871-006e987c7cb0',
        view_committee_reports_section: '90f8651a-5682-4cf1-87db-acca1f35b024',
        country_member_list_report: '82b30b1d-47c3-4694-9870-c388608b1d00'
      },
      fields: [
        { key: 'report', label: 'Report', sortable: true, width: '1%' },
        { key: 'documents', label: '', sortable: false },
      ],
      reportFields: [],
      hiddenItems: {
        display: false,
      },
      addItems: {
        display: false,
      },
      report_categories: [],
      reports_list: [],
      translated_reports_list: [],
      items: [],
      is_country_member_list_accessible: false,
      is_909_accessible: false,
    }
  },
  methods: {
    ...mapActions({
      getControlPermissions: 'user/getControlPermissions',
      loadReports: 'report/loadReports',
      setLoadingStatus: 'menu/setLoadingStatus',
    }),
    async page_load(evt) {
      if (!evt) {
        // execute only if evt is undefined
        try {
          this.setLoadingStatus(true)
          await Promise.all([await this.loadReports(), await this.checkPermissions()]).then(() => {
            this.report_categories = this.iCanSee(this.secured_controls.view_secure_reports_section)
              ? this.reportcategories
              : this.reportcategories.filter((rc) => rc.Parent_rpc_key !== 'a67aedf3-a61d-4be5-ac3e-0471aa4881cf')
            this.report_categories = this.iCanSee(this.secured_controls.view_committee_reports_section)
              ? this.report_categories
              : this.report_categories.filter((rc) => rc.Parent_rpc_key !== '313d7887-0694-48fe-803d-92c5c3d31780')
            this.reports_list = this.is_909_accessible ? this.reports : this.reports.filter((tr) => tr.Child_rpc_key !== '30099fc0-19b7-4192-984e-9d2a66ca2018')
            // This is the first report-level filter. If more follow, we'll restructure this code. 
            let countryMbrRptList = this.reports_list.filter(rl => rl.vrpt_rpt_key === this.secured_controls.country_member_list_report)
            if (countryMbrRptList.length > 0) {
              if (this.iCanSee(this.secured_controls.view_country_member_list_report) === false) {
                this.reports_list = this.reports_list.filter(rl => rl.vrpt_rpt_key != this.secured_controls.country_member_list_report)
              }
            }
            this.translateReportNames()
          })
        } catch (e) {
          console.error(e)
        } finally {
          this.setLoadingStatus(false)
        }
      }
    },
    async checkPermissions() {
      await this.getControlPermissions(this.secured_controls.view_909_secure_reports).then(() => {
        this.is_909_accessible = this.determineAccessibility(this.secured_controls.view_909_secure_reports)
      })
    },
    translateReportNames() {
      this.translated_reports_list = this.reports_list.map((item) => {
        let newItem = Object.assign({}, item, {
          display_text: !!this.translations.components['report-parameters'][item.sitecore]
            ? this.translations.components['report-parameters'][item.sitecore]
            : item.vrpt_rpt_name_trn_txt,
        })
        return newItem
      })
    },
    hideCondition(category) {
      var hideSection = false
      switch (category.Parent_rpc_key) {
        case '03fcdc2e-711c-4966-b1ed-900ce284e401':
          // for State Reports section
          // if ICC user, or USA user but officer toolbar is International
          hideSection =
            (['ICC', 'icc', 'IOC', 'ioc', 'USA', 'usa'].includes(this.userLogin.membertype)) &&
            !!this.officerToolbarSelected.camp_number &&
            this.officerToolbarSelected.camp_number.substring(0, 1) === 'I'
          break

        case '9a16d6f0-bcbb-419d-8342-4ff9e102731b':
          // for Country Reports section
          hideSection =
            (['ICC', 'icc', 'IOC', 'ioc', 'USA', 'usa'].includes(this.userLogin.membertype)) &&
            !!this.officerToolbarSelected.camp_number &&
            this.officerToolbarSelected.camp_number.substring(0, 1) === 'U'
          break

        // for Monthly Camp Reports and GideonCard Reports sections
        case '9105b976-fc78-40f6-98d2-1391984cae58':
        case '751ae0bd-6f6f-4ae3-bc56-39dbf81b0629':
          hideSection =
            !!this.officerToolbarSelected.camp_number && this.officerToolbarSelected.camp_number.substring(0, 1) === 'I'
          break
      }
      return hideSection
    },
    openSection(vc) {
      return {
        'Church Reports': 'cm',
        'Committee Reports': 'cr',
        'GideonCard Reports': 'gc',
        'Membership Reports': 'mb',
        'Monthly Camp Reports': 'mc',
        'Candidate Reports': 'ca',
        'Scripture Distributions Reports': 'sd',
        'Secure Reports': 'sr',
        'State Reports': 'st',
        'Country Reports': 'cn',
      }[vc || '']
    },
    loadTranslations() {
      this.reportFields = [
        { key: 'display_text', label: `${this.translations.tcReportName}`, sortable: true },
        { key: 'documents', label: '', sortable: false },
      ]
    },
    translateSection(parentCategory) {
      return {
        'Church Reports': this.translations.tcChurchReports,
        'Committee Reports': this.translations.tcCommitteeReports,
        'GideonCard Reports': this.translations.tcGideonCardsReports,
        'Membership Reports': this.translations.tcMembershipReports,
        'Monthly Camp Reports': this.translations.tcMonthlyCampReports,
        'Candidate Reports': this.translations.tcProspectReports,
        'Scripture Distributions Reports': this.translations.tcScriptureDistributionsReports,
        'Secure Reports': this.translations.tcSecureReports,
        'State/Country Reports': this.translations.tcStateOrCountryReports,
        'State Reports': this.translations.tcStateReports,
        'Country Reports': this.translations.tcCountryReports,
      }[parentCategory || '']
    },
  },
  computed: {
    ...mapGetters({
      iCanSee: 'user/iCanSee',
      isInMenu: 'menu/isInMenu',
      officerToolbarSelected: 'user/officerToolbarSelected',
      prefCulture: 'user/userPreferredCulture',
      reportcategories: 'report/reportcategories',
      reports: 'report/reports',
      userLogin: 'user/userLogin',
    }),
    allowPage() {
      let rpt_type = this.$route.path.split('/')[3]
      return ['cn', 'cr', 'sr', 'st'].includes(rpt_type) ? true : this.isInMenu(this.$route.path)
    },
    reportCategoryList() {
      let findex = this.report_categories.findIndex(
        (y) => this.openSection(y.ParentCategory) === this.$route.path.split('/')[3]
      )
      if (findex > -1) {
        let top_category = this.report_categories.splice(findex, 1)
        this.report_categories = [...top_category, ...this.report_categories]
      }
      return this.report_categories
    },
  },
  async created() {
    try {
      this.setLoadingStatus(true)
      await Promise.all([
        await this.getTranslations(),
        this.loadTranslations(),
        await this.getComponentTranslations('camp-select', 'data-table', 'security-banner'),
        await this.getPseudoComponentTranslations('report-parameters'),
      ]).then((results) => {
        const componentTranslations = results[2]
        const pseudoComponentTranslations = results[3]
        this.$set(this.translations, 'components', componentTranslations)
        for (const [key, value] of Object.entries(pseudoComponentTranslations)) {
          this.translations.components[key] = value
        }
      })
      await this.page_load()
    } catch (e) {
      console.error(e)
    } finally {
      this.setLoadingStatus(false)
    }
  },
  components: {
    CampSelector: CampSelect,
    DataTable: DataTable,
    pageBody: pageBody,
    pageFooter: pageFooter,
    pageHeader: pageHeader,
    securityBanner: SecurityBanner,
    ToggleArrow: ToggleArrow,
  },
}
</script>

<style lang="scss">
@import '@/styles/settings.scss';

.church-members-friends {
  margin-bottom: 60px;

  .search-reports {
    display: flex;
    margin-top: 50px;

    input {
      flex: 1 1 auto;
      background-color: #f5f5f5;
      border: 1px solid #636363;
      padding: 13px 17px;
      font-family: $open-sans;
      font-size: 16px;
      color: #636363;
      font-weight: 700;
    }
  }

  .list.container {
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 40px;
  }

  .closed-toggle {
    padding: 40px 30px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    h2 {
      flex: 1 1 auto;
      margin: 0;
      color: #000;
      font-family: $DIN;
      font-size: 42px;
      letter-spacing: 1.4px;
      line-height: 44px;
      text-transform: uppercase;
    }

    .toggle-arrow svg {
      transform-origin: center;
      transform: rotate(180deg);
    }
  }
}
</style>
